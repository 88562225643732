@import '../../../styles/globalVariables';

.searchTopDDContainer {
  display: flex;
  align-items: center;
  @media screen and (max-width:1199px) {
   width: 100%;
  }
}
.searchTopDDContainer.openSearchDrop{
  &:after{
    content: '';
    background: rgb(0 0 0 / 38%);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    top: 172px;
    opacity: 0;
  }
@include for-size(tablet-phone) { 
    z-index: 999;
    position: relative;
    &:after{
      top: inherit;
      opacity: 1;
    }
  }
}
.searchPLP {
  height: 50px;
  width: 360px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  border: 1px solid #D2D2D7;
  background: #fff;
  padding: 0 15px;
  position: relative;
  label{
    color: rgba(74, 76, 79, 0.6);
    padding-left: 12px;
    line-height: normal;
    font-size: 16px;
  }
  @media only screen and (max-width: 1199px){
    width: 100%;
    height: 40px;
    border-radius: 40px;
    margin-top: 0px;
    padding: 0 10px;
    margin-right: 12px;
  }
  html[dir='rtl'] & {
    margin-right: 0px;
    margin-left: 26px;
    @include for-size(tablet-phone) {
      margin-left: 12px;
      &.searchPLPOpen {
        margin-left: 0px;
      }
    }
  }
  &.searchPLPOpen {
    border-radius: 20px 20px 0px 0px;
    height: 50px;
    @include for-size(tablet-phone ){
    margin-right: 0px;
    height: 50px;
    z-index: 9;
  }
  i{
    html[dir='rtl'] & {      
      padding-top: 0;
      @media (max-width:1199px){
      transform: rotate(180deg);
      }
    }
  }

  }
  &::after {
    content: '';
    position: absolute;
    right: -16px;
    height: 29px;
    width: 1px;
    background-color: rgba(74, 76, 79, 0.36);
    html[dir='rtl'] & {
      right: unset;
      left: -15px;
    }
    @media screen and (max-width:1199px) {
      display: none;
    }
  }
  i {
    cursor: pointer;
    display: flex;
  
    @include for-size(tablet-phone) {
      font-size: 16px;
      &:last-child {
        font-size: 10px;
      }
      
    }
  }
  .mobileThreeDotsHide {
    display: none;
  } 
  input {
    width: 100%;
    height: 40px;
    border: 0;
    background: transparent;
    padding: 0 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    outline: none;
    color: #4C4C4C;
    &::placeholder {
      font-weight: 400;
      color: rgba(74, 76, 79, 0.6);
    }
    @include for-size(tablet-phone) {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $color-gray;
    }
  }
}
.mobileFilterWrapper {
  display: none;
  padding: 10px;
  border: 1px solid #D2D2D7;
  background: #fff;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  &.mobileFilterHide {
    display: none;
  }
  @include for-size(tablet-phone) {
    display: block;
  }
}
.selectListWrapper {
  width: 360px;
  // min-height: 140px;
  transform: translateX(0);
  top: 50px;
  background: white;
  border: 1px solid #dddddd;
  border-top: 0px;
  border-radius: 0px 0px 5px 5px;
  box-sizing: border-box;
  position: absolute;
  text-align: left;
  padding: 0px;
  z-index: 90;
  @include for-size(tablet-phone) {
    width: 100%;
    margin: auto;
    z-index: 999;
    top: 50px;
  }
  html[dir='rtl'] & {
    // right: 0px;
    // left: 0px;
  }
  &.searchPLPClose {
    display: none;
  }
}
.openHeaderListContainer{
  @include for-size(tablet-phone) {
    height: 70vh !important;
  }
}
.selectListContainer {
  overflow-x: hidden;
  overflow-y: overlay;
  max-height: 411px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #fff; 
  } 
  &::-webkit-scrollbar-thumb {
    background: #a3a3a3; 
    border-radius:25px;
  }  
  @include for-size(tablet-phone) {  
    max-height: 80vh;
  }
  html[dir='rtl'] & {
    text-align: right;
  }
  li {
    text-transform: capitalize;
    padding: 10px 20px;
    cursor: pointer;
    
    &:last-child {
      margin-bottom: 18px;
    }
    &.cityHeading{
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: rgba(115, 115, 115, 0.5);
      margin-top: 10px;
    }
    &.cityName {
      text-transform: unset;
      font-size: 16px;
      line-height: 24px;
      color: $color-gray;
      font-weight: 500;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(74, 76, 79, 0.6);
        margin-left: 5px;
      }

      &:hover {
        background: #ebebeb;
      }
    }
    &.allCities{
      margin-top: 10px;
    }
    &.idNumber {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: $color-gray;
      .idText {
        font-weight: 500;
      }
      .idTitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: block;
        margin-top: 4px;
      }
    }
    &.selected {
      background: #f9f5fe;
      color: #6f10a2;
      span {
        color: #6f10a2;
      }
    }
  }
}
.ddContainer{
  display: flex;
  align-items: center;
  width: 100%;  
  padding: 0 10px;
  .ddWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 15px;
    html[dir='rtl'] & {
      margin-right: 0;
      margin-left: 15px;
    }
    &:last-child {
      margin-right: 0px;
    }
    .ddSelectedCityName {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: $color-gray;
      margin-right: 5px;
      html[dir='rtl'] & {
        margin-right: 0;
        margin-left: 5px;
      }
    }
    .ddSelectedZone {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #6F10A2;
      margin-right: 5px;
      html[dir='rtl'] & {
        margin-right: 0;
        margin-left: 5px;
      }
    }
  }
}
.openFilterDetails{
  border-radius: 20px 20px 0px 0px;
  @include for-size(tablet-phone){
    height: 50px;
  }
}
.HomeSrWrapper{
  -webkit-flex-basis: 100%;
    flex-basis: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;    
    .searchPLP{
      width: 100%;
      margin-right: 0;
      background: #fff;
      border-radius: 40px;
      position: relative;
      -webkit-box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
      box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
      padding: 10px;
      cursor: pointer;
      height: 72px;
      border-top: 1px solid hsla(270,6%,51%,.251);
      html[dir='rtl'] & {
        margin-left: 0;
      }
      &:after{
        display: none;
      }
      &.searchPLPOpen{
        border-radius: 35px 35px 0px 0px;
      }
      input{
        &::-webkit-input-placeholder {
              color:$color-gray;
        }        
        &:-ms-input-placeholder {
          color:$color-gray ;
        }        
        &::placeholder {
          color:$color-gray ;
        }
      }
      @include for-size(tablet-phone ){
        height: 50px;
        border-radius: 24px;
        &.searchPLPOpen{
          border-radius: 24px 24px 0px 0px;
        }
      }
    }
    .homeSrIcon{
      width: 56px;
      height: 56px;
      border-radius: 50%;
      position: absolute;
      right: 10px;
      background: #6f10a2;
      z-index: 7;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
        i{
          color: #fff;
          font-size: 31px;
        }
        @include for-size(tablet-phone ){
          height: 40px;
          width: 40px;
          right: 8px;
          i{
            font-size: 20px;
          }
          html[dir='rtl'] & {
            left:8px !important;
          }
        }
        html[dir='rtl'] & {
         left:10px;
         right: inherit;
        }
    }
    .disable {
      pointer-events: none;
      cursor: not-allowed;
      background-color: #737373;
    }
}

.HomeSrDrop{
  width: 100%;
  top: 72px;
  background: #fff;
  -webkit-box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  .selectListContainer{
    max-height: 333px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #fff; 
    } 
    &::-webkit-scrollbar-thumb {
      background: #a3a3a3; 
      border-radius:25px;
    }  
  }
  @include for-size(tablet-phone ){
    top: 50px !important;
    }
}
.mSearchF{
  position: relative;
  margin-right: 30px;
  html[dir='rtl'] & {
    margin-left: 0;
    margin-right: 0;
   }
  @media only screen and (max-width: 1199px){
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @include for-size(phone-only) {  
    margin: 0 20px !important;  
  }
}

.mt_18 {
  margin-top: 18px;
}