@import '../../../../styles/globalVariables';

.lowerFooter {
  display: grid;
  grid-template-columns: 284px 2fr;
  align-items: center;
  padding: 48px 0px;
  grid-gap: 48px;
  max-width: 1228px;
  margin: 0 auto;

  @include for-size(phone-only) {
    padding: 32px 2.5vw;
    grid-template-columns: unset;
  }

  & .socialIcons {
    @media (max-width: 1024px) {
      display: none;
    }
  }
}

.legal {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  grid-gap: 64px;
  color: $color-gray;
  font-weight: 400;

  @include for-size(phone-only) {
    padding: 32px 0 0 0;
    grid-template-columns: repeat(2, auto);
    grid-gap: 28px;
    border-top: 1px solid #cacbcd;
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 481px) {
    display: flex;
    width: 100%;
    gap: 64px;
    margin-left: 0;
  }
}

.language {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
  align-content: center;
  grid-gap: 8px;
  margin-left: 50px;
  margin-right: 50px;
  font-size: 13px;
  vertical-align: middle;
  align-self: center;
  padding-top: 3px;
  &_link {
    color: $color-gray;
    font-weight: 600;
    @include for-size(tablet-portrait-up) {
      vertical-align: middle;
      align-self: center;
    }
  }
  @include for-size(phone-only) {
    margin-left: 0;
    margin-right: 0;
  }
}
.privacyLink {
  cursor: pointer;
  color: inherit;
}
