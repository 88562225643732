// ==========================================================================
///  Placeholders for Buttons / CTAs
///
///  Created Date: 26-10-2021
///  @author: Ajit Kayastha
///  Contact: akayastha@innovito.in
//
// ==========================================================================

@import '../functions';
@import '../variables';
@import '../mixins';
@import '../fx';

// Basic Button
// ==========================================================================
%button {
  position: relative;
  padding: 16px;
  display: flex;
  place-content: center;
  place-items: center;
  gap: 8px;
  appearance: none;
  font-family: inherit;
  line-height: 1.5;
  border: 1px solid;
  pointer-events: initial;
  cursor: pointer;
  user-select: none;

  border-radius: 4px;

  color: map-deep-get($button-text-color, 'default', 'default');
  background-color: map-deep-get($button-background-color, 'default', 'default');
  border-color: map-deep-get($button-border-color, 'default', 'default');

  &:hover{
    @include for-size(desktop-up) {
      color: map-deep-get($button-text-color, 'default', 'hover');
      background-color: map-deep-get($button-background-color, 'default', 'hover');
      border-color: map-deep-get($button-border-color, 'default', 'hover');
    }
  }

  &:active {
    color: map-deep-get($button-text-color, 'default', 'pressed');
    background-color: map-deep-get($button-background-color, 'default', 'pressed');
    border-color: map-deep-get($button-border-color, 'default', 'pressed');
  }

  &[disabled] {
    color: map-deep-get($button-text-color, 'default', 'disabled');
    background-color: map-deep-get($button-background-color, 'default', 'disabled');
    border-color: map-deep-get($button-border-color, 'default', 'disabled');
    cursor: not-allowed;
    pointer-events: none;
  }
}

// Button Styles
// ==========================================================================
%button-default {
  @include button-style('default');
}

%button-primary {
  @include button-style('primary');
}

%button-secondary {
  @include button-style('secondary');
}

%button-ascent {
  @include button-style('ascent');
}

%button-info {
  @include button-style('info');
}

%button-muted {
  @include button-style('muted');
}

%button-success {
  @include button-style('success');
}

%button-warning {
  @include button-style('warning');
}

// Button Border Radius
// ==========================================================================
%button-radius-none {
  border-radius: 0;
}

%button-radius-4 {
  border-radius: 4px;
}

%button-radius-8 {
  border-radius: 8px;
}

// Button Sizes
// ==========================================================================

%button-sm {
  @include button-style('default');
}

%button-lg {
  @include button-style('default');
}

%button-full-width {
  width: 100%;
}
