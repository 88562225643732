@import '../../styles/fx.scss';
@import '../../styles/functions';
@import '../../styles/variables';
@import '../../styles/mixins';

@import '../../styles/placeholders';
@import '../../styles/globalVariables';

.noAuctionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 331px;
  flex-direction: column;
  gap: 16px;

  @include for-size(phone-only) {
    height: 394px;
    padding: 0px 16px;
  }

  & .noAuctionText {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #0a050e;
    display: block;
    text-align: center;
  }
  .noAuctionBtn{
    display: flex;
    border-radius: 8px;
    height: 40px;
    padding: 0px 12px;
    border: none;
    background: transparent;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #6F10A2;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

.checkBoxWrapper {
  display: grid;
  position: relative;
  left: 25%;
  background-color: #fff;
  margin-top: -50px;
  padding: 20px;
  width: 50%;
  grid-template-columns: repeat(5, auto);

  [dir='rtl'] & {
    left: auto;
    right: 25%;
  }

  @include for-size(tablet-portrait-up) {
    margin-top: -70px;
    grid-template-columns: repeat(3, auto);

    :nth-child(4) {
      left: 50%;

      [dir='rtl'] & {
        right: 50%;
      }
    }

    :nth-child(5) {
      left: 50%;

      [dir='rtl'] & {
        right: 50%;
      }
    }
  }

  @include for-size(phone-only) {
    grid-template-columns: repeat(2, auto);
    width: 100%;
    left: 0;
    margin-top: 0;
    padding-bottom: 0;

    [dir='rtl'] & {
      right: 0;
    }
  }
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: 8px;
  padding-top: 50px;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  text-align: center;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  &__title {
    font-weight: 800;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.75em;
    text-align: center;
    text-transform: uppercase;
    background: #864ef2;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    width: max-content;

    @include for-size(phone-only) {
      font-size: 12px;
    }

    [dir='rtl'] & {
      letter-spacing: 0em;
    }
  }

  &__subtitle {
    color: #6f10a2;
    font-weight: bold;
    font-size: 39px;
    line-height: 46px;

    @include for-size(phone-only) {
      font-size: 36px;
      line-height: 40px;
    }
  }
}

.locator {
  position: absolute;
  z-index: 999;
  top: 88.6%;
  right: 0;
  margin-right: -6px;

  @include for-size(tablet-portrait-up) {
    top: 87%;
    margin-right: -5px;
  }

  @include for-size(phone-only) {
    top: 82%;
  }
}

.info {
  font-weight: 400;
  color: #9a92a0;
}

.tags_cityselectopr_wrapper {
  padding-left: 0;
  padding-right: 0;
  padding-top: 12px;
  margin-bottom: 33px;
  padding-bottom: 12px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 11;
  background: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

  &.auctionMenuFixed {
    z-index: 103;
    height: 74px;

    @media screen and (max-width: 979px) {
      height: auto;
    }
  }

  .inrContainer {
    max-width: 1228px;
    width: 100%;
    display: grid;
    margin: auto;
    grid-template-columns: 390px 1fr 145px;
    align-items: center;

    @include for-size(tablet-phone) {
      display: block;
    }
  }

  html[dir='rtl'] & {
    grid-template-columns: 390px 1fr 127px;
  }

  @media screen and (max-width:1199px) {
    box-shadow: none;
    margin-bottom: 0;
    padding-top: 12px;
  }

  @media screen and (max-width: 1280px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @include for-size(tablet-phone) {
    display: block;
    padding-bottom: 0;
  }

  @include for-size(phone-only) {
    display: block;
  }

  @include for-size(tablet-portrait-up) {
    margin-bottom: 24px;
  }
}

.actionButns {
  display: flex;
  gap: 24px;
}

.menuDropdown {
  @include for-size(tablet-phone) {
    display: flex;
  }
  place-items: center;
  padding: 8px 7px 8px 12px;
  border-radius: 4px;
  position: relative;
  max-width: 126px;
  height: 40px;
  border: 1px solid #D2D2D7;
  box-shadow: 0px 9px 17px rgba(0, 0, 0, 0.0243888),
    0px 2.71324px 5.125px rgba(0, 0, 0, 0.035),
    0px 1.12694px 2.12866px rgba(0, 0, 0, 0.0456112),
    0px 0.407592px 0.769896px rgba(0, 0, 0, 0.07);
  margin-left: auto;
  cursor: pointer;

  html[dir='rtl'] & {
    margin-right: auto;
    margin-left: 0;
  }

  @media screen and (max-width: 767px) {
    width: 40px;
    height: 40px;
    position: relative;
    z-index: 122;
    box-shadow: none;
    border: 1px solid #D2D2D7;
    border-radius: 40px;
    padding: 0;
  }

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    margin-left: auto;
    position: relative;
  }

  [dir='rtl'] & {
    padding: 8px 8px 8px 0px;

    @include for-size(phone-only) {
      padding-right: 0;

      &::before {
        left: auto;
        right: 0px;
      }
    }
  }
}

.dropdownWrapper {
  width: 100%;
  border-radius: 10px;
  background-color: transparent;
  user-select: none;
  display: inline-block;

  .dropdownHeader {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    border-radius: 12px;
    white-space: nowrap;

    .menutitle {
      font-size: 13px;
      color: $color-gray;
      font-weight: 600;
    }

    @include for-size(phone-only) {
      justify-content: center;
      border-radius: 8px;
    }

    .arrow {
      width: 10px;
      height: 10px;
      border-top: 2px solid #fafafa;
      border-left: 2px solid #fafafa;
      transform: rotate(-135deg);

      left: 11px;

      &.openArrow {
        transform: rotate(45deg);
      }
    }
  }

  .dropdownBody {
    background-color: $white;
    padding: 5px;
    border-radius: 15px;
    border-top: 1px solid #e5e8ec;
    display: none;
    list-style: none;
    color: #3c3544;
    font-weight: bold;
    position: absolute;
    z-index: 100;
    min-width: 100%;
    width: auto;
    white-space: nowrap;
    box-shadow: 0 0 3px;
    box-shadow: 0px 8px 30px rgba(161, 168, 191, 0.4), 0px 0px 2px rgba(0, 0, 0, 0.2);

    &.open {
      display: block;
    }

    .dropdownItem {
      padding: 5px 25px;
      cursor: pointer;
      margin-bottom: 8px;

      @include for-size(phone-only) {
        padding: 5px 15px;
      }

      &:hover {
        background-color: #ebeaed;
        border-radius: 7px;
      }

      &:active,
      &.selected {
        background-color: #1d74ff;
        color: #fff;
        border-radius: 7px;
      }

      span {
        background-image: url(../../../public/images/dropLi.png);
        width: 15px;
        height: 15px;
        position: relative;
        background-size: contain;
        display: inline-block;
        top: -3px;
        margin-inline-start: 12px;
      }
    }
  }

  &.secondary {
    line-height: 24px;

    .dropdownHeader {
      background: #faf8fc;
      border: 1px solid #a28db4;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 14px;
      line-height: 24px;
      padding: 7px 30px 7px 20px;
      color: #73558c;

      .arrow {
        top: 4px;
        border-top: 2px solid #73558c;
        border-left: 2px solid #73558c;

        &.openArrow {
          top: 0;
          transform: rotate(-135deg);
        }
      }
    }

    .invisibleDropdownBody {
      right: 0;

      [dir='rtl'] & {
        right: unset;
        left: 0;
      }
    }

    .dropdownBody {
      border-radius: 4px;
      border: 1px solid#a82594;

      li {
        font-size: 13px;

        &:active,
        &.selected {
          background-color: #a82594;
          border-radius: 4px;
        }

        span {
          background-image: url(../../../public/images/dropLi.png);
          width: 15px;
          height: 15px;
          position: relative;
          background-size: contain;
          display: inline-block;
          top: 0;
        }
      }
    }
  }

  .ghostDropdownHeader {
    cursor: pointer;
    display: flex;
    -moz-box-pack: justify;
    justify-content: space-between;
    -moz-box-align: center;
    align-items: center;
    font-size: 1rem;
    color: #3c3544;
    font-style: normal;
    font-weight: 500;

    .arrow {
      width: 10px;
      height: 10px;
      border-top: 2px solid;
      border-left: 2px solid;
      transform: rotate(135deg);
      position: relative;
      left: 7px;

      &.openArrow {
        transform: rotate(225deg);
      }
    }
  }

  .menutitle {
    @include for-size(phone-only) {
      display: none;
    }
  }

  .ghostDropdownBody {
    background-color: #ffffff;
    display: none;
    list-style: none;
    position: absolute;
    right: 0;
    min-width: 216px;
    z-index: 90;
    box-shadow: 0px 8px 30px rgba(161, 168, 191, 0.4), 0px 0px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-top: 12px;

    li {
      border-bottom: 1px solid #ededed;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      color: #1D252D;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      height: 44px;
      i{
        color: #485661;
        display: inline-flex;
        margin-right: 12px;
        html[dir='rtl'] & {
          margin-left: 12px;
          margin-right: 0;
        }
      }

      svg {
        display: inline-block;
        vertical-align: top;
        margin-right: 12px;
      }
    }

    li:hover {
      background-color: #F9F5FE;
    }

    li:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    li:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    @include for-size(phone-only) {
      min-width: 189px;
      margin-top: 16px;
    }

    [dir='rtl'] & {
      left: 0px;
      right: auto;

      svg {
        margin-right: 0px;
        margin-left: 12px;
        margin-top: 4px;
      }
    }

    &.open {
      display: grid;
    }

    .dropdownItem {
      padding: 10px 20px;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 500;
      color: $color-gray;

      @include for-size(phone-only) {
        padding: 5px 15px;
      }

      &:hover {
        background-color: #ebeaed;
        border-radius: 4px;
      }

      &:active,
      &.selected {
        background-color: #6f10a2;
        color: #fff;
        border-radius: 4px;
      }

      span {
        background-image: url(../../../public/images/dropLi.png);
        width: 13px;
        height: 13px;
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        top: 5px;

        @media screen and (max-width:767px) {
          left: 25%;
        }

        [dir='rtl'] & {
          float: left;
          @media screen and (max-width:767px) {
            right: 60%;
          }
        }

      }
    }
  }

  .invisibelDropdownHeader {
    cursor: pointer;
    display: flex;
    -moz-box-pack: justify;
    justify-content: space-between;
    -moz-box-align: center;
    align-items: center;
    font-size: 0.8rem;
    color: #685d71;
    font-style: normal;
    font-weight: 300;

    .arrow {
      width: 10px;
      height: 10px;
      border-top: 2px solid;
      border-left: 2px solid;
      transform: rotate(225deg);
      position: relative;
      left: 11px;
      top: 2px;

      @include for-size(phone-only) {
        left: 7px;
      }

      &.openArrow {
        transform: rotate(45deg);
      }
    }
  }

  .invisibleDropdownBody {
    background-color: #ffffff;
    padding: 7px;
    border-top: 1px solid #e5e8ec;
    display: none;
    list-style: none;
    position: absolute;
    width: 180px;
    z-index: 90;
    box-shadow: 0px 8px 30px rgb(161 168 191 / 40%), 0px 0px 2px rgb(0 0 0 / 20%);
    border-radius: 4px;
    margin-top: 20px;
    grid-gap: 4px;

    [dir='rtl'] & {
      width: 210px;
      left: -12%;
    }

    @include for-size(tablet-portrait-up) {
      width: 170px;
    }

    @include for-size(phone-only) {
      width: 177px;

      [dir='rtl'] & {
        width: 202px;
        left: -10%;
      }
    }

    &.open {
      display: grid;
    }

    .dropdownItem {
      padding: 8px;
      cursor: pointer;
      font-size: 0.8rem;
      font-weight: 500;
      border-radius: 4px;

      @include for-size(phone-only) {
        padding: 5px 8px;
      }

      &:hover {
        background-color: #ebeaed;
      }

      &:active,
      &.selected {
        background-color: #6f10a2;
        color: #fff;
      }

      span {
        background-image: url(../../../public/images/dropLi.png);
        width: 13px;
        height: 13px;
        position: relative;
        background-size: contain;
        display: inline-flex;
        top: 2px;
        margin-left: 20px;

        &.shiftLeft {
          margin-left: 0px;
          margin-right: 10px;
        }

        [dir='rtl'] & {
          top: 3px;
        }
      }
    }
  }
}

.dropdown__icon {
  margin-left: 7px;

  i {
    display: flex;
    color: #96989C;
    font-size: 16px;

  }

  html[dir='rtl'] & {
    margin-right: auto;
  }

  @media screen and (max-width:767px) {
    margin-inline-start: 0;
    margin: auto;
  }
}

.grid_view_results {
  position: relative;
  max-width: 1228px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  padding-bottom: 60px;


  @include for-size(laptop-down) {
    padding-left: 25px;
    padding-right: 25px;
    gap: 20px;
  }
}

.navTabs {
  display: flex;
  white-space: nowrap;
  gap: 8px;
  padding: 5px 0 5px;
  margin: 0;
  place-items: center;
  align-items: center;
  max-width: 646px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 0;
    opacity: 0;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #a3a3a3;
    border-radius: 25px;
  }

  @include for-size(tablet-phone) {
    padding: 16px 24px 16px 0px;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include for-size(phone-only) {
    padding-left: 24px;

  }

  @include for-size(tablet-portrait-up) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width:1199px) {
    margin-left: 0;
  }
}

.tab_active div img,
.tab_inactive div img {
  min-width: initial !important;
  max-width: initial !important;
  min-height: 100% !important;
  max-height: 100% !important;
  width: 16px !important;
  height: 16px !important;
  left: -36px !important;

  &.arb {
    left: inherit !important;
    right: 10px !important;
  }
}

.tab_active {
  @extend %button;
  @extend %button-secondary;
  padding: 0 20px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  border-color: #6f10a2 !important;
  background-color: #F9F5FE !important;
  color: $color-gray;
  font-size: 14px;
  line-height: normal;
  vertical-align: bottom;

  @include for-size(tablet-phone) {
    padding: 0 16px;
    height: 32px;
  }
  &:hover,
  &:focus,
  &:active {
    border-color: #6f10a2 !important;
    background-color: #F9F5FE !important;
    color: $color-gray;

    @include for-size(tablet-phone) {
      padding: 0 16px;
      height: 32px;
    }
  }
}

.tab_inactive {
  @extend %button;
  @extend %button-secondary;
  padding: 0 20px;
  height: 40px;
  cursor: pointer;
  color: $color-gray;
  background-color: transparent;
  border-color: transparent;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #D2D2D7;
  border-radius: 20px;

  [dir='rtl'] & {
    font-weight: 400;
  }

  @include for-size(tablet-phone) {
    padding: 0 16px;
    height: 32px;
  }

  &:hover,
  &:focus,
  &:active {
    line-height: normal;
    border-color: #6f10a2 !important;
    background-color: #F9F5FE !important;
    color: $color-gray;
  }
}


@media screen and (min-width: 768px) and (max-width: 1039px) {
  .tags_cityselectopr_wrapper {
    top: 62px;
  }
}

@media screen and (max-width: 767px) {
  .grid_view_results {
    grid-gap: 16px;
    padding: 0 20px 30px 20px;
  }

  .tags_cityselectopr_wrapper {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0px;
    padding-top: 12px;

    position: sticky;
    top: 0;
    z-index: 95;
    background-color: white;
  }

  .header {
    padding-bottom: 24px;
  }
}


@media screen and (min-width: 992px) and (max-width: 1199px) {
  .navTabs {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .tags_cityselectopr_wrapper {
    display: block;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 0px;

    .inrContainer {
      grid-template-columns: 325px 1fr;
    }
  }

  .header {
    padding-bottom: 12px;
  }
}

@media screen and (max-width: 1600px) {
  .auction_grid_view_results {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

.textCenter {
  text-align: center;
}

.cursor_pointer {
  cursor: pointer !important;
}

.liveAuc {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;

  &:before {
    position: absolute;
    display: block;
    margin-right: 4px;
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgba(12, 118, 81, 0.4);
    -webkit-animation: glowing 1500ms infinite;
    -moz-animation: glowing 1500ms infinite;
    -o-animation: glowing 1500ms infinite;
    animation: glowing 1500ms infinite;

    html[dir='rtl'] & {
      margin-right: 0;
      margin-left: 4px;
    }
  }

  &:after {
    margin-right: 4px;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 3px;
    top: 3px;
    background-color: #0C7651;
  }

  @keyframes glowing {
    0% {
      background-color: #F9F5FE;
    }

    50% {
      background-color: rgba(12, 118, 81, 0.4);
    }

    100% {
      background-color: #F9F5FE;
    }
  }
}

.plpBox {
  display: flex;
  align-items: center;

  .btnRight {
    display: none;
    background: #FFFFFF;
    border: 1px solid #D2D2D7;
    width: 32px;
    height: 32px;
    box-shadow: 0px 9px 17px rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    font-family: $primary-font;
    margin: 0 0px 0 10px;

    html[dir='rtl'] & {
      margin: 0 10px 0 0px;
    }

    &.btnLeft {
      margin: 0 10px 0 0px;

      html[dir='rtl'] & {
        margin: 0 0px 0 10px;
      }
    }

    i {
      font-size: 10px;
    }

    @include for-size(tablet-phone) {
      display: none;
    }
  }

}

.cityselectoprMask {
  background: rgb(0 0 0 / 38%);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  top: 0px;
  z-index: 10;

  @media screen and (max-width: 767px) {
    z-index: 102;
    display: none;
  }
}

.filterBackdrop {
  @media screen and (max-width: 767px) {
    z-index: 90;
    display: block;
  }
}

.isNotMobile {
  @include for-size(tablet-up) {
    display: none;
  }
}
.isNotDesktop {
  @include for-size(phone-only) {
    display: none;
  }
}