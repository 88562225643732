@import '../../../styles/globalVariables';

.social {
  position: relative;
  &__list {
    display: grid;
    grid-template-columns: repeat(6, 24px);
    grid-gap: 24px;
    align-items: center;
    @include for-size(phone-only) {
      justify-content: start;
    }
  }
  a {
    display: block;
    width: 24px;
    height: 24px;
  }
}
