// ==========================================================================
///  Color Variables
///
///  Created Date: 18-10-2021
///  @author: Ajit Kayastha
///  Contact: akayastha@innovito.in
///
// ==========================================================================

//  Color Map
// ==========================================================================
$hue: (
  'violet': (
    '50': #fbf6fe,
    '100': #f5e8fd,
    '200': #e2baf8,
    '300': #cf8bf3,
    '400': #bc5dee,
    '500': #a82fea,
    '600': #8f15d0,
    '700': #6f10a2,
    '800': #510c76,
    '900': #300745,
  ),
  'gray': (
    '0': #ffffff,
    '50': #f2f2f3,
    '100': #e5e5e6,
    '200': #cbcccd,
    '300': #b0b2b5,
    '400': #96989c,
    '500': #7c7e83,
    '600': #636569,
    '700': #4a4c4f,
    '800': #323335,
    '900': #19191a,
    '1000': #000000,
  ),
  'orange': (
    '50': #fff5e5,
    '100': #ffe6c2,
    '200': #ffd28f,
    '300': #ffbe5c,
    '400': #ffa929,
    '500': #f39200,
  ),
  'purple': (
    '50': #f9f5fe,
    '100': #e8defd,
    '200': #c8aef9,
    '300': #a77ff6,
    '400': #864ef2,
  ),
  'blue': (
    '50': #f5f9ff,
    '100': #ebf2ff,
    '200': #b8d3ff,
    '300': #84b4ff,
    '400': #5294ff,
    '500': #1d74ff,
    '600': #005beb,
  ),
  'indigo': (
    '50': #e7e7f9,
    '100': #d0d1f0,
    '200': #aaabe4,
    '300': #8385d8,
    '400': #5c5fcc,
    '500': #3b3eba,
    '600': #2e3192,
  ),
  'green': (
    '50': #e8fdf5,
    '100': #baf8e2,
    '200': #8bf3cf,
    '300': #5defbc,
    '400': #2feaa8,
    '500': #15d08f,
    '600': #10a26f,
    '700': #0c7651,
  ),
  'black': #000000,
  'white': #ffffff,
  'red': #d02850,
);
// ==========================================================================
// ==========================================================================

// ==========================================================================
// ==========================================================================

// Gradients
// ==========================================================================
$gradients: (
  'vivid-orange-purple':
    linear-gradient(90deg, map-deep-get($hue, 'orange', '500') 6.38%, map-deep-get($hue, 'purple', '400') 100%),
);

// ==========================================================================
// ==========================================================================

$black: map-deep-get($hue, 'gray', '1000') !default;
$white: map-deep-get($hue, 'gray', '0') !default;

// Text Colors
// ==========================================================================
$text-color-default: map-deep-get($hue, 'gray', '700') !default;
$text-color-primary: map-deep-get($hue, 'violet', '800') !default;
$text-color-secondary: map-deep-get($hue, 'violet', '700') !default;
$text-color-white: $white !default;
$text-color-black: $black !default;

$text-color-muted: map-deep-get($hue, 'gray', '400') !default;
$text-color-info: map-deep-get($hue, 'blue', '500') !default;
$text-color-success: map-deep-get($hue, 'green', '700') !default;
$text-color-warning: map-deep-get($hue, 'orange', '500') !default;
$text-color-error: map-get($hue, 'red') !default;
$text-color-transparent: transparent !default;

// Text Colors for Elements
$text-color-heading: $text-color-primary !default;
$text-color-title: $text-color-secondary !default;
$text-color-subtitle: map-deep-get($hue, 'gray', '600') !default;
$text-color-body: map-deep-get($hue, 'gray', '700') !default;
$text-color-section: map-deep-get($hue, 'gray', '700') !default;
$text-color-article: map-deep-get($hue, 'gray', '700') !default;
$text-color-paragraph: map-deep-get($hue, 'gray', '700') !default;

// ==========================================================================
// ==========================================================================

// Background Colors
// ==========================================================================
$background-color-default: map-deep-get($hue, 'gray', '0') !default;
$background-color-primary: map-deep-get($hue, 'blue', '500') !default;
$background-color-secondary: map-deep-get($hue, 'violet', '800') !default;
$background-color-ascent: map-deep-get($hue, 'purple', '400') !default;
$background-color-info: map-deep-get($hue, 'violet', '800') !default;
$background-color-muted: map-deep-get($hue, 'gray', '200') !default;
$background-color-success: map-deep-get($hue, 'green', '500') !default;
$background-color-warning: map-deep-get($hue, 'orange', '500') !default;
$background-color-error: map-get($hue, 'error') !default;
$background-color-transparent: transparent !default;

// Background Colors for Elements
$body-bg: $background-color-default !default;
$component-bg: map-deep-get($hue, 'gray', '0') !default;
$modal-bg: map-deep-get($hue, 'gray', '50') !default;
// ==========================================================================
// ==========================================================================

// Border Colors
// ==========================================================================
$border-color-default: map-deep-get($hue, 'gray', '300') !default;
$border-color-primary: map-deep-get($hue, 'blue', '500') !default;
$border-color-secondary: map-deep-get($hue, 'violet', '800') !default;
$border-color-ascent: map-deep-get($hue, 'purple', '400') !default;
$border-color-info: map-deep-get($hue, 'violet', '800') !default;
$border-color-muted: map-deep-get($hue, 'gray', '200') !default;
$border-color-success: map-deep-get($hue, 'green', '500') !default;
$border-color-warning: map-deep-get($hue, 'orange', '500') !default;
$border-color-error: map-get($hue, 'error') !default;
$border-color-transparent: transparent !default;
// ==========================================================================
// ==========================================================================

// Link Colors
// ==========================================================================
$link-color-default: map-deep-get($hue, 'violet', '700') !default;
$link-color-hover: map-deep-get($hue, 'violet', '800') !default;
$link-color-focus: map-deep-get($hue, 'violet', '800') !default;
$link-color-active: map-deep-get($hue, 'violet', '600') !default;
$link-color-visited: map-deep-get($hue, 'violet', '500') !default;
// ==========================================================================
// ==========================================================================
