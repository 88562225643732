@import '../../../styles/globalVariables';

.btn {
  padding: 8px 24px;
  border: none;
  background: transparent;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // margin-bottom: 5px;
  cursor: pointer;

  &.pdg {
    padding: 8px 12px !important;
    width: auto;
  }
  &.pdgv1 {
    padding: 3px 15px !important;
    width: auto;
  }
  span {
    display: block;
  }
  &.hide {
    display: none;
  }
  .text {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #ffffff;
    text-transform: capitalize;
    &.purple {
      color: #8d75a2 !important;
      white-space: nowrap;
    }
  }

  .textfont {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding: 7px 7px 7px 0px;
  }

  .icon {
    width: 16px;
    height: 16px;
    display: none;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.iconOnly {
    .icon {
      margin: 0;
      display: block;
    }

    .text {
      display: none;
    }
  }
  &.reverse {
    flex-direction: row-reverse !important;
    .icon {
      margin-inline-start: 10px !important;
      // margin-right: 0 !important;
      // margin-left: 4px;
    }
  }
  &.withIcon {
    .icon {
      margin-inline-end: 8px;
      display: block;
    }
  }
  &.right {
    float: right;
  }
  &.magenta {
    padding: 8px 32px;
    background-color: #a82594;
    border-radius: 4px;
  }
  &.darkVoilet {
    padding: 5px 20px;
    background-color: #6f10a2;
    border-radius: 4px;
  }
  &.darkVoiletV2 {
    padding: 5px 20px;
    background-color: #6f10a2;
    border-radius: 4px;
    min-width: 131px;
  }
  &.voilet {
    padding: 8px 16px;
    background: #73558c;
    border-radius: 4px;
  }
  &.blue {
    padding: 8px 16px;
    background: #1d74ff;
    border-radius: 4px;
  }
  &.blue-o {
    padding: 8px 16px;
    background: #84b4ff;
    border-radius: 4px;
  }

  &.turquoise {
    padding: 4px 16px;
    background: #57c0c3;
    border-radius: 4px;
  }

  &.Schedule {
    padding: 1em 3em;
    color: #0070f3;
  }

  &.white {
    background: #ffffff;
    border: 1px solid #d4cadb;
    box-sizing: border-box;
    box-shadow: 0px 9px 17px rgba(0, 0, 0, 0.0243888), 0px 2.71324px 5.125px rgba(0, 0, 0, 0.035),
      0px 1.12694px 2.12866px rgba(0, 0, 0, 0.0456112), 0px 0.407592px 0.769896px rgba(0, 0, 0, 0.07);
    .text {
      color: #685d71;
    }
  }
  &.green {
    padding: 12px 20px;
    background: #25d366;
    border-radius: 4px;
  }
  &.skyblue {
    padding: 12px 20px;
    background: #57c0c3;
    border-radius: 4px;
  }
  &.purple {
    background-color: #4b276b;
    .text {
      font-size: 27px;
    }
  }
  &.btn-signIn {
    max-height: 36px;
    padding: 12px;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
  
    .text {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; 
      color: #6F10A2;
      text-transform: unset !important;
    }
  }
  &.btn-signInProfile {
    max-height: 36px;
    padding: 12px;
    border-radius: 8px;
    white-space: nowrap;
    border: 1px solid  #4C4C4C;
    background: #F5F6F8;
    cursor: pointer;
  
    .text {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px; 
      color: #191919;
      text-transform: unset !important;
    }
  }
  &.navMenuMobile--btn {
    border-radius: 8px;
    border: 1px solid $violet;
    background: $white; 
    padding: 12px 16px;
    margin-bottom: 16px;
    height: 32px;
  
    .text {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; 
      color: $violet;
    }
  }
  &.white-red {
    //Add Property Button
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    $border: 2px;
    background: #fff;
    background-clip: padding-box;
    border: solid $border transparent;
    border-radius: 1em;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -$border;
      border-radius: inherit;
      background: linear-gradient(to top, #ff4166, #ebeaed);
    }
    .text {
      color: #ff4166;
    }
  }
  &.seeOnMap {
    background: #f9f5fe;
    border: 1px solid #c8aef9;
    color: #6f10a2 !important;
    font-size: 13px;
    .purple {
      color: currentColor;
    }
    .text {
      color: currentColor;
    }
    border-radius: 4px;
  }

  &.clearAll {
    background: #ffffff;
    border: 1px solid #b0b2b5;
    .text {
      color: #3c3544;
      font-size: 13px;
    }
    border-radius: 4px;
  }

  &.voilet-o {
    background: #ffffff;
    border: 1px solid #a82fea;
    color: #6f10a2;
    font-size: 13px;
    border-radius: 4px;

    .text {
      color: currentColor;
    }
  }

  &.popup-yes {
    padding: 4px 16px;
    background: #0070f3;
    border-radius: 7px;
    margin-right: 10px;
  }
  &.popup-no {
    padding: 4px 16px;
    background: #0070f3;
    border-radius: 7px;
    margin-right: 10px;
  }
  &.grey {
    padding: 32px;
    background: #323335;
    border-radius: 0;
    border-right: 0.5px solid #534e58;
    &:hover {
      background-color: #444444;
    }
  }
  &.exploreWhite {
    background: #ffffff;
    border-radius: 0;
    justify-content: start;
    align-content: center;
    padding-left: 2.5vw;
    width: 40vw;
    height: 100%;
    .text {
      font-weight: normal;
      font-size: 14px;
      color: #837b8b;
    }
    &:hover {
      background: #eee;
    }
  }
  &.whiteIcon {
    //this class is custom class specifically for property detail page. Please do not add/remove any css from here
    background: #fff;
    padding: 24px 10px;
    border-radius: 0;
    border-radius: 4px;
    height: 20px;
    box-shadow: 0px 9px 17px rgb(0 0 0 / 6%), 0px 2.71324px 5.125px rgb(0 0 0 / 4%),
      0px 1.12694px 2.12866px rgb(0 0 0 / 5%), 0px 0.407592px 0.769896px rgb(0 0 0 / 7%);
  }
  &.lightGreen {
    background: #d1f1e6;
    padding: 18px;
    border-radius: 4px;
    height: 0px;
    border: 2px solid transparent;
    .text {
      color: #035739;
    }
    &:hover {
      border: 2px solid #035739;
    }
  }
  &.lightSkyBlue {
    background: #57c0c326;
    padding: 12px 20px;
    border-radius: 4px;
    border: 2px solid #57c0c3;
    .text {
      color: #048558;
    }
    &:hover {
      border: 2px solid #57c0c3;
    }
  }
  &.transparent {
    padding: 10px;
  }
  &.homeSearch {
    padding: 20px 30px;
    background-color: #6f10a2;
    border-radius: 10px;
    @include for-size(phone-only) {
      padding: 15px 20px;
      border-radius: 7px;
    }
    .text {
      font-size: $h1-font-size;
      font-weight: $font-semi-bolder;
      @include for-size(phone-only) {
        font-size: $h3-font-size;
      }
    }
    .icon {
      width: 30px;
      height: 30px;
    }
  }
  &.homeSearchV2 {
    background-color: $matchmaker-autofill-color-border;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    padding: 0px 12px;
    .text {
      font-size: $font16;
      font-weight: $font-semi-bold;
      line-height: 24px;
    }
    .icon {
      width: 15px;
      height: 15px;
    }
  }
  &.linkBtn {
    background-color: transparent;
    .text {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: #300745;
      line-height: 24px;
    }
  }
  &.linkBtnDescription {
    padding: 8px 0px;
    background-color: transparent;
    .text {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: #300745;
      line-height: 24px;
    }
  }
  &.btnTransparent {
    background: #ffffff;
    border: 1px solid #510c76;
    color: #510c76;
    font-size: 13px;
    font-weight: bold;
    border-radius: 4px;
    line-height: 24px;
    padding: 12px 25px 12px 25px;

    .text {
      color: currentColor;
    }
  }

  &.dropdownToggle {
    padding: 7px 7px 7px 20px;
    background-color: #FFFFFF;
    border: 1px solid rgba(70, 87, 104, 0.3);
    border-radius: 30px;
    
    html[dir='rtl'] & {
      padding-left: 7px;
      padding-right: 20px;
    }

    &.active {
      background-color: #F9F5FE;
      border-color: #6F10A2;

      i {
        line-height: 24px;
        transform: rotateX(180deg);
      }
    }

    &.selected {
      border-color: #6F10A2;
    }

    .text {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #4C4C4C;
      margin-right: 4px;

      html[dir='rtl'] & {
        margin-right: 0px;
        margin-left: 4px;
      }
    }

    i {
      font-size: 8px;
      width: 24px;
      height: 24px;
      line-height: 28px;
      position: relative;
    }

    @include for-size(phone-only) {
      padding: 3px 3px 3px 16px;

      html[dir='rtl'] & {
        padding-left: 3px;
        padding-right: 16px;
      }
    }
  }

  &.applyFilter {
    background-color: #6F10A2;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    min-width: 98px;

    @include for-size(phone-only) {
      font-weight: 500;
      line-height: 14px;
      padding: 9px 0px;
      min-width: 75px;
      width: 75px;
      box-shadow: 0px 9px 17px rgba(0, 0, 0, 0.0243888), 0px 2.71324px 5.125px rgba(0, 0, 0, 0.035), 0px 1.12694px 2.12866px rgba(0, 0, 0, 0.0456112), 0px 0.407592px 0.769896px rgba(0, 0, 0, 0.07);
    }
  }
}

//add icons here

.whatsapp {
  margin: 0;
  display: block;
  background-image: url(../../assets/images/icons/whatsapp.png);
  width: 16px;
  height: 16px;
}
.call {
  display: block;
  background-image: url(../../assets/images/icons/phone.svg);
  width: 16px;
  height: 16px;
}
.search {
  display: block;
  background-image: url(../../assets/images/icons/search.svg);
  width: 16px;
  height: 16px;
}
.search-icon {
  display: block;
  background-image: url(../../assets/images/icons/search.svg);
  width: 15px;
  height: 15px;
  margin: 0px 10px 0px 7px !important;
}
.calendar {
  display: block;
  background-image: url(../../assets/images/icons/calendar.svg);
  width: 16px;
  height: 16px;
}
.circleArrowDown {
  display: block;
  background-image: url(../../assets/images/icons/arrowDown.svg);
  width: 16px;
  height: 16px;
}
.leftArrow {
  display: block;
  background-image: url(../../assets/images/icons/leftArrow.svg);
  width: 16px;
  height: 16px;
}
.rightArrow {
  display: block;
  background-image: url(../../assets/images/icons/rightArrow.svg);
  width: 16px;
  height: 16px;
}
.degreeView {
  display: block;
  background-image: url(../../assets/images/icons/360degree.svg);
  width: 24px !important;
  height: 24px !important;
}
.pdf {
  display: block;
  background-image: url(../../assets/images/icons/pdf.svg);
  width: 24px !important;
  height: 24px !important;
}
.floorPlan {
  display: block;
  background-image: url(../../assets/images/icons/floorPlan.svg);
  width: 24px !important;
  height: 24px !important;
}
.wishlist {
  display: block;
  background-image: url(../../assets/images/icons/Fav.svg);
  width: 16px !important;
  height: 14px !important;
}
.share {
  display: block;
  background-image: url(../../assets/images/icons/share.svg);
  width: 21px !important;
  height: 17px !important;
}
.filter {
  display: block;
  background-image: url(../../assets/images/icons/filters.svg);
  width: 12px !important;
  height: 12px !important;
}
.phone {
  display: block;
  background-image: url(../../assets/images/icons/phone1.svg);
  width: 12px !important;
  height: 12px !important;
}
.downArrow {
  display: block;
  background-image: url(../../assets/images/icons/seeMoreDownArrow.svg);
  width: 18px !important;
  height: 9px !important;
}
.upArrow {
  display: block;
  background-image: url(../../assets/images/icons/seeMoreDownArrow.svg);
  width: 18px !important;
  height: 9px !important;
  transform: rotate(180deg);
}
.location {
  display: block;
  background-image: url(../../assets/images/icons/location.svg);
  width: 12px !important;
  height: 12px !important;
}

// --------------------------------TABLET MEDIA STYLE -----------------------------------------------------------
@media (max-width: 768px) {
  .btn {
    &.exploreWhite {
      padding-left: 2.5vw;
    }
  }
}

// --------------------------------MOBILE PHONE MEDIA STYLE -----------------------------------------------------------

@media (max-width: 375px) {
  .btn {
    &.exploreWhite {
      display: none;
    }
  }
}

// --------------------------------RTL STYLE -----------------------------------------------------------
html[dir='rtl'] {
  @media (min-width: 770px) {
    .btn {
      &.exploreWhite {
        padding-right: 45px;
        padding-left: 440px;
      }
    }
  }
  .search-icon {
    display: block;
    background-image: url(../../assets/images/icons/search_flip.svg);
    width: 15px;
    height: 15px;
    margin: 0px 7px 0px 10px !important;
  }
  .textfont {
    padding: 7px 0px 7px 7px;
  }
}

.backButton {
  text-transform: none !important;
}