@import "../../../styles/globalVariables";

.dropdown {
    background-color: white;
    position: relative;

    @include for-size(phone-only) {
        position: static;
    }
}


.dropdownMenu {
    width: 100%;
    min-width: 428px;
    max-width: 428px;
    background-color: #FFFFFF;
    border-radius: 4px;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 0px;
    top: calc(100% + 15px);

    html[dir='rtl'] & {
        left: auto;
        right: 0px;
    }

    @include for-size(tablet-phone) {
        box-shadow: 0px 8px 30px rgba(161, 168, 191, 0.4), 0px 0px 2px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        border-top: none;
    }

    @include for-size(phone-only) {
        top: 100%;
        left: 12.5px;
        right: 12.5px;
        width: calc(100% - 25px);
        min-width: inherit;

        html[dir='rtl'] & {
            left: 12.5px;
            right: 12.5px;
        }
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
    }

    &:before {
        content: "";
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-bottom: 10px solid rgba($color: #000000, $alpha: 0.2);
        left: 24px;
        top: -10px;

        html[dir='rtl'] & {
            left: auto;
            right: 24px;
        }

        @include for-size(tablet-phone) {
            display: none;
        }
    }
    &:after {
        content: "";
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 9px solid #FFFFFF;
        left: 25px;
        top: -9px;

        html[dir='rtl'] & {
            left: auto;
            right: 25px;
        }

        @include for-size(tablet-phone) {
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #FFFFFF;
            top: -6px;
            left: 57px;

            html[dir='rtl'] & {
                left: auto;
                right: 57px;
            }
        }

        @include for-size(phone-only) {
            left: 24px;

            html[dir='rtl'] & {
                left: auto;
                right: 24px;
            }
        }
    }

    &.mobileDropdodownOpenArrowPosition_2 {
        @include for-size(phone-only) {
            &:after {
                left: 160px;

                html[dir='rtl'] & {
                    left: auto;
                    right: 150px;
                }
            }
        }
    }

    .menuList {
        padding: 16px 24px 24px;

        @include for-size(phone-only) {
            padding: 12px 16px;
        }

        span {
            display: inline-block;
            width: 100%;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $color-gray;
        }

        ul {
            margin-top: 16px;
            display: flex;
            gap: 20px;

            @include for-size(phone-only) {
                gap: 16px;
            }
        }

        li {
            background: #FFFFFF;
            border: 1px solid #B0B2B5;
            border-radius: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $color-gray;
            padding: 11px;
            text-align: center;
            width: calc((100% - 20px) / 2);
            cursor: pointer;

            &.active {
                background-color: #F9F5FE;
                border-color: #6F10A2;
                color: #6F10A2;
                font-weight: 500;
            }

            @include for-size(phone-only) {
                width: calc((100% - 16px) / 2);
                padding: 7px;
            }
        }
    }

    .menuFilter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px;
        border-top: 1px solid #E5E5E6;

        @include for-size(phone-only) {
            border-top: none;
            padding: 20px 16px 12px;
        }

        span {
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: $color-gray;
            text-decoration: underline;
            cursor: pointer;

            @include for-size(phone-only) {
                font-weight: 500;
                line-height: 10px;
            }
        }
    }

    .menuFilterClear {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 16px 24px;
        border-top: 1px solid #E5E5E6;

        @include for-size(phone-only) {
            border-top: none;
            padding: 20px 16px 12px;
        }
    }
}


.cityselectoprMask {
    background: rgba(0, 0, 0, 0.38);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    top: 0px;
    z-index: 10;
}
