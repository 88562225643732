@mixin for-size($size) {
	@if $size == phone-only {
		@media only screen and (max-width: 767px) {
			@content;
		}
	} @else if $size == landscape-phone {
		@media only screen and (max-width: 575px) {
			@content;
		}
	}@else if $size == phone-lg {
		@media only screen and (max-width: 480px) {
			@content;
		}
	}@else if $size == phone-md {
		@media only screen and (max-width: 375px) {
			@content;
		}
	} @else if $size == phone-sm {
		@media only screen and (max-width: 359px) {
			@content;
		}
	}  @else if $size == landscape-mobile {
		@media only screen and (min-width: 576px) {
			@content;
		}
	}
	 @else if $size == tablet-phone {
		@media only screen and (max-width: 991px) {
			@content;
		}
	} @else if $size == tablet-portrait-up {
		@media only screen 
			and (min-device-width: 768px) 
			and (max-device-width: 1024px) 
			and (orientation: portrait) 
			and (-webkit-min-device-pixel-ratio: 1) {
				@content;
			}
	} @else if $size == tablet-landscape-up {
		@media only screen and (min-width: 992px) {
			@content;
		}
	} @else if $size == laptop-up {
		@media only screen and (min-width: 1280px) {
			@content;
		}
	} @else if $size == laptop-down {
		@media only screen and (max-width: 1280px) {
			@content;
		}
	} @else if $size == laptop-up {
		@media only screen and (min-width: 1280px) {
			@content;
		}
	} @else if $size == laptop-down {
		@media only screen and (max-width: 1280px) {
			@content;
		}
	}@else if $size == desktop-up {
		@media only screen and (min-width: 1200px) {
			@content;
		}
	} @else if $size == desktop-down {
		@media only screen and (max-width: 1200px) {
			@content;
		}
	} @else if $size == big-desktop-up {
		@media only screen and (min-width: 1800px) {
			@content;
		}
	} @else if $size == small-desktop-down {
		@media only screen and (max-width: 1320px) {
			@content;
		}
	} @else if $size == tablet-up {
		@media only screen and (min-width: 768px) {
			@content;
		}
	} @else if $size == big-tablet-up {
		@media only screen and (min-width: 1024px) {
			@content;
		}
	} @else if $size == big-tablet-down {
		@media only screen and (max-width: 1024px) {
			@content;
		}
	}
	@else if $size == large-desktop {
		@media only screen and (min-width: 1920px) {
			@content;
		}
	}
}

@mixin text-ellipse($size) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: $size;
}

@mixin center-position-bg-image {
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-position: center center;
}
