@import '../../../styles/globalVariables';

.header {
  position: sticky;
  width: 100%;
  height: 88px;
  border-bottom: 1px solid #F3F6FA;
  background: $white;
  box-shadow: 0px 4px 24px 0px rgba(208, 208, 209, 0.12);
  padding: $pad0 $pad12;
  z-index: 99;
  top: 0;
  transition: all 0.3s ease-in-out;

  @include for-size(tablet-up){
    height: auto;
    border-bottom: none;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  }
  @media screen and (max-width: 979px) {
    height: 53px;
  }

  &.sticky {
    border-bottom: 1px solid #F3F6FA;
    background: $white;
    box-shadow: 0px 4px 24px 0px rgba(208, 208, 209, 0.12);
    z-index: 99;
    @media screen and (max-width: 979px) {
      height: 53px;
    }
  }

  &.navbaropen {
    background-color: #fff;
  }

  .hidden {
    transform: translateY(-100%);
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  gap: 12px;
  padding: 3px 0px;

  @include for-size(tablet-landscape-up) {
    padding: 13px 0px;
  }

  @include for-size(big-tablet-up) {
    max-width: 990px;
  }

  @include for-size(desktop-up) {
    max-width: 1160px;
  }

  @include for-size(laptop-up) {
    max-width: 1228px;
    gap: 24px;
  }

  @include for-size(tablet-phone) {
    padding: $pad10 $pad0;
  }

  @include for-size(tablet-up){
    justify-content: unset;
    padding: $pad16 $pad0;
  }
}

.homeheader {
  background-color: rgb(0 0 0 / 80%);
}
.backBtn{
  width: 32px;
  height: 32px;
  border: 1px solid #96989C;
  border-radius: 50%;
  background: transparent;
}
.openDrop{
  width: 193px;
  background: #FFFFFF;
  border-radius: 8px;
  padding:0px 10px;
  box-shadow: 0px 8px 30px rgba(161, 168, 191, 0.4), 0px 0px 2px rgba(0, 0, 0, 0.2);
  li{
    a{
      span{
        margin-right: 12px;
      }
      font-size: 16px;
      color: $color-gray;
      border-bottom: 1px solid #E5E5E6;
      padding: 10px 0;
      display: grid;
      grid-template-columns: auto auto auto;
      align-items: center;
      font-weight: 400;
      small{
        color: #737373;
        display: block;
      }
    }
    &:first-child{border: 0;}
  }
}


.languagePop{
  width: 240px;
  border-radius: 4px;
  background: #ffffff;
  padding: 16px 18px;
  h3{
    font-weight: 500;
    color: $color-gray;
    padding-bottom: 18px;
  }
  .selectOp{
    label {
      display: block;
      padding-bottom: 15px;
      cursor: pointer;
      >input[type="radio"] {
        display: none;
        + {
          * {
            &::before {
              content: "";
              display: inline-block;
              vertical-align: sub;
              width: 20px;
              height: 20px;
              margin-right: 10px;
              border-radius: 50%;
              border-style: solid;
              border-width: 2px;
              border-color: $color-gray;
            }
          }
        }
        &:checked {
          + {
            * {
              color: #6F10A2;
              &::before {
                background: radial-gradient(#6F10A2 0%, #6F10A2 40%, transparent 50%, transparent);
                border-color: #6F10A2;
              }
            }
          }
        }
      }
    }
    
    span{
      font-weight: 400;
      font-size: 14px;
      color: #6F10A2;
    }
  }
  
  .closeLanguage{
    text-align: right;
    margin-top: 15px;
    button{
      background: transparent;
      font-weight: 500;
      font-size: 14px;
      color: #6F10A2;
      border: 0;
      cursor: pointer;
    }
  }
  
}


.backPage{ 
  width: 24px;
  height: 24px;  
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    display: flex;
    color: #000;
    html[dir='rtl'] & {
      transform: rotate(180deg);
    }
  }
}
  .hiconBox {
    display: flex;
    margin-left: auto;
    align-items: center;
    gap: 24px;
    & i{
      display: flex;
    }
    html[dir='rtl'] & {
      margin-right: auto;
      margin-left: 0;
    }
    .threeDotsicon{
      position: relative;

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
        color: #212121;
      }
    }
    .sharePdpH{
      html[dir='rtl'] & {
        transform: rotateY(180deg);
      }

      i {
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        color: #212121;
      }
    } 
  }
  .pdpfixedtext{
    h4{
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 155px;
    }
    span{
      display: block;
      font-size: 12px;
      font-weight: 400;
      color: #737373;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 155px;
    }
    @include for-size(phone-md){
      h4{
        max-width: 131px;
      }
      span{
        max-width: 131px;
      }

    }

  }

    .mobCustomPdp{
      @include for-size(tablet-phone) {
      grid-template-columns: 0fr auto 1fr !important;
      
    }
    @include for-size(phone-lg){
      grid-gap: 10px;
    }

}
.posInherit{
  position: relative;
}

.hide {
  display: none;
}

@include for-size(tablet-up) {
  .isNotMobile {
    display: none !important;
  }
}
@include for-size(phone-only) {
  .isNotDesktop {
      display: none !important;
  }
}

@include for-size(tablet-up) {
  .headerCTA{
    order: 3;
    margin-left: auto ;
    html[dir='rtl'] & {
      margin-left: 0px;
      margin-right: auto;
      }
  }
  .navWrapperDesktop{
    order: 2;
  }
}
