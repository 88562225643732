@import './fx.scss';

// Color

$primary-font: Jost, Noto Kufi Arabic, sans-serif;
$arabic-font: 'Noto Kufi Arabic', sans-serif;
$primary-bg: #fff;
$per100:100%;
$per90:90%;
$per80:80%;
$per70:70%;
$per60:60%;
$per50:50%;
$per40:40%;
$per30:30%;
$per20:20%;
$per10:10%;

//LINE HEIGHT

$lineH8:8px;
$lineH10:10px;
$lineH12:12px;
$lineH14:14px;
$lineH16:16px;
$lineH18:18px;
$lineH20:20px;
$lineH24:24px;
$lineH28:28px;
$lineH30:30px;
$lineH32:32px;

// Font size
$font10: 10px;
$font11: 11px;
$font12: 12px;
$font13: 13px;
$font14: 14px;
$font15: 15px;
$font16: 16px;
$font17: 17px;
$font18: 18px;
$font19: 19px;
$font20: 20px;
$font21: 21px;
$font22: 22px;
$font23: 23px;
$font24: 24px;
$font25: 25px;
$font26: 26px;
$font27: 27px;
$font28: 28px;
$font29: 29px;
$font30: 30px;
$font31: 31px;
$font32: 32px;
$font33: 33px;
$font34: 34px;

//transition
$theme-transition1: all 0.3s ease;
$theme-transition2: all 0.5s ease;
$theme-transition3: all 1s ease;

//Breakpoint
$break-xl: 1199px;
$break-lg: 1023px;
$break-md: 767px;
$break-sm: 575px;

//Margin
$mrg0: 0;
$mrg2: 2px;
$mrg1: 1px;
$mrg4: 4px;
$mrg5: 5px;
$mrg6: 6px;
$mrg7: 7px;
$mrg8: 8px;
$mrg10: 10px;
$mrg12: 12px;
$mrg15: 15px;
$mrg17: 17px;
$mrg16: 16px;
$mrg20: 20px;
$mrg24: 24px;
$mrg25: 25px;
$mrg28: 28px;
$mrg30: 30px;
$mrg35: 35px;
$mrg40: 40px;
$mrg45: 45px;
$mrg50: 50px;
$mrg55: 55px;
$mrg60: 60px;
$mrg32: 32px;

//padding
$pad0: 0px;
$pad2: 2px;
$pad4: 4px;
$pad5: 5px;
$pad6:6px;
$pad7: 7px;
$pad8:8px;
$pad10: 10px;
$pad12: 12px;
$pad15: 15px;
$pad16: 16px;
$pad20: 20px;
$pad24: 24px;
$pad25: 25px;
$pad30: 30px;
$pad32: 32px;
$pad35: 35px;
$pad36: 36px;
$pad40: 40px;
$pad45: 45px;
$pad50: 50px;
$pad55: 55px;
$pad60: 60px;

/* Variables */

/* base url for images */
$image-base-url: 'https://assets.daralarkan.com';

/* Color Variables */
$Cool-Grey-700: #36434E !default;
$Cool-Grey-900: #1D252D !default;
$Cool-Grey-050: #F3F6FA !default;
$black: #000000 !default;
$white: #ffffff !default;
$primary-color: #eeeeee !default;
$secondary-color: #4b276b !default;
$tertiary-color: #ebeaed !default; // used in menu background
$heading-color: #510c76 !default;
$button-dim-violer:#C198D9;
$heading-light: #73558c !default;
$warning-color: #ff974c !default;
$error-color: #d50000 !default;
$default-color: #464646 !default;
$background-color: #f2f2f2 !default;
$card-border-light: #efefef !default;
$card-border-dark: #e2e2e2 !default;
$background-gray-light: #f8f8f8 !default;
$background-mute: #454343;
$secondary-white: #f0ebf9;
$color-mute-dark: #685d71;
$matchmaker-autofill-color: #F0EBF9;
$matchmaker-autofill-color-border: #6F10A2;
$color-mute: #837b8b;
$transparent: transparent;
$light-textcolor: #363636;
$magento: #a82594;
$purple: #73558c;
$green-dark: #048558;
$light-golden:#FBF2E4;
$golgen: #C37D13;
$color-green-light: #4CAF50;
$high-dark-green : #2B8700;
$profile-revamp-green: #29831A;
$profileicbgcolor:#f5f6f8;
$light-green:#EBF5E5;
$green-light: #d1f1e6;
$sky-blue: #57c0c3;
$sky-blue-light: #39caad15;
$border-color-light: #e5e5e6;
$card-border-hover: #864ef2 !important;
$fav-text-color: #035739;
$lightBlack: #191919;
$Cool-Grey-100: #E1E4E8;
$color-gray: #4A4C4F;
$color-grey-600: #4c4c4c;
$color-gray-light: #737373;
$color-dim-black: #222222;
$grey-secondary: #ECECF0;
$grey-light-primary: #D9D9E0;
$requireTag-color:#ee5441;
$new-brand-blue:#2149C1;
$light-blue:#E9EDF9;
$revamp-color-one:#F9F5FE;
$revampbg-color:#f5f6f8;
$revamp-color-two:#F7F7F7;
// New Branding colors
$blue: #1d74ff;
$violet: #6f10a2;
$dull-white: #d2d2d2;
$Cool-Grey-500: #566470 !default;

$skeleton-color: linear-gradient(#ECECF0, #D9D9E0);


// New Branding colors
$blue: #1d74ff;
$violet: #6f10a2;
$lightBlack:#191919;
$greyColor:#737373;
$lightgrey: #4C4C4C;
$lightBlack2:#24282C;
$blue-varient2:#3972F6;
$featuredColor:#9747FF;
$lightYellow:#FEF9F0;
$amber:#FBD192;
$card-border-light2:#d2d2d2;
$Red-50: #FFF7F7;
$Red-200: #F9C4BE;

/* Font-size */
$h0-font-size: 3rem !default;
$h1-font-size: 2.5rem !default;
$h2-font-size: 2.2rem !default;
$h3-font-size: 2rem !default;
$h4-font-size: 1rem !default;
$h5-font-size: 24px !default;
$h6-font-size: 20px !default;
$h7-font-size: 18px !default;
$default-font-size: 16px !default;
$h8-font-size: 14px !default;
$h9-font-size: 13px !default;
$h10-font-size: 12px !default;
$h11-font-size: 11px !default;
$font-size-responsive: 1.6vw !default;
/* Font-weight */
$font-light: 300 !default;
$default-font-weight: 400 !default;
$font-semi-bold: 500 !default;
$font-semi-bolder: 600 !default;
$font-bold: 700 !default;
$font-bolder: 800 !default;

/* Border Raduis */
$radius0: 0px;
$radius1: 2px;
$radius2: 4px;
$radius3: 6px;
$radius4: 8px;
$radius5: 10px;
$radius6: 12px;
$radius20: 20px;

/* gap */
$gap8: 8px;
$gap4: 4px;

/* height */
$height44: 44px;
$height48: 48px;

@mixin checkboxFlex {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}
@mixin dispflexcenter{
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin dispflexstart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
@mixin dispflexspecbtw{
    display: flex;
    align-items: center;
    justify-content:space-between; 
}

@mixin ewalletrefereshtemp {
    display: flex;
    align-items: center;

    @include for-size (phone-only) {
        display: block;
    }
}
@mixin sidemenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border-radius: 8px;
    background-color: $white;
    cursor: pointer;   
      .signinTextNavDrawer {
        width: 170px;
        word-break: break-word;
        color: #191919;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        
        html[dir='rtl'] & {
            @include for-size(phone-only) {
                width: 163px;
            }
        }
    }
}


@mixin profileBoxMenu{
    .menuProfileTabsIcon {
        width: 48px;
        height: 48px;
        border-radius: 12px;
        border: 1px solid $revamp-color-one;
        background: $revamp-color-one;
        padding: 0px;

    }
    .ProfileBox {
        width: 48px;
        height: 48px;
        border-radius: 100%;
        background: $revamp-color-two;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
     
  
          i{
            display: flex;
            color: #737373;
            font-size: 28px;
            width: 28px;
            height: 28px;
       
          }
          .pTag{
            position: absolute;
            top: -4px;
            right: -1px;
            width: 16px;
            height: 16px;
            img{
                width: 14px;
              }
            
          }
        }
      

    .menuTextIconWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .tabTitle {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: $lightBlack;
        }

        .tabDesc {
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            color: #737373;
        }

        span {
            i {
                display: block;
                width: 16px;
                height: 16px;
                font-size: 16px;
                color: #737373;
            }
        }
    }

}

@mixin skeleton {
    .amountSkeleton {

        .ewalletText {
            display: block;
            width: 120px;
            height: 20px;
            border-radius: $radius2;
            background: linear-gradient(107deg, $grey-secondary -9.08%, $grey-light-primary 109.23%);
            margin-bottom: 4px;
        }

        .amount {
            display: block;
            width: 120px;
            height: 32px;
            border-radius: $radius2;
            background: linear-gradient(107deg, $grey-secondary -9.08%, $grey-light-primary 109.23%);
        }

    }

    .refBtnSkeleton {
        display: flex;
        align-items: center;

        @include for-size (phone-only) {
            align-items: flex-start;
        }

        .refBtn {
            display: block;
            width: 91px;
            height: 32px;
            border-radius: $radius2;
            background: linear-gradient(107deg, $grey-secondary -9.08%, $grey-light-primary 109.23%);
        }
    }
}

@mixin followIcon{

    position: absolute;
    top:14px;
    right: 14px;
    display:flex;
    align-items: center;
    justify-content: center;
    width:32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 3;
    background-color:#F9F5FE;
    html[dir='rtl'] &{
      right: auto;
      left: 14px;
    }
    i{
      display: block;
      width: 16px;
      height: 16px;
    }
    }