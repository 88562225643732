// ==========================================================================
///  Mixins for Buttons / CTAs
///
///  Created Date: 26-10-2021
///  @author: Ajit Kayastha
///  Contact: akayastha@innovito.in
//
// ==========================================================================

// Mixin for Button Styles
@mixin button-style($style) {
  color: map-deep-get($button-text-color, $style, 'default');
  background-color: map-deep-get($button-background-color, $style, 'default');
  border-color: map-deep-get($button-border-color, $style, 'default');

  &:hover{
    @include for-size(desktop-up) {
      color: map-deep-get($button-text-color, $style, 'hover');
      background-color: map-deep-get($button-background-color, $style, 'hover');
      border-color: map-deep-get($button-border-color, $style, 'hover');
    }
  }

  &:active {
    color: map-deep-get($button-text-color, $style, 'pressed');
    background-color: map-deep-get($button-background-color, $style, 'pressed');
    border-color: map-deep-get($button-border-color, $style, 'pressed');
  }

  &[disabled] {
    color: map-deep-get($button-text-color, $style, 'disabled');
    background-color: map-deep-get($button-background-color, $style, 'disabled');
    border-color: map-deep-get($button-border-color, $style, 'disabled');
  }
}