@import '../../../styles/globalVariables';
.footer {
  background: #eeeeee;
  margin-top: 32px;
  padding: $pad0 $pad20;
  @include for-size(phone-only) {
    margin-top: 24px;
  }
}
.footerHomePage {
  margin-top: 0;
}
.container {
  width: 100%;
  padding: 60px 0px 0px;
  max-width: 1228px;
  margin: 0 auto;
  @include for-size(phone-only) {
    padding: 32px 0px 0px;
  }
}

.logo {
  display: flex;
  flex-direction: column;
  flex: 1;
  .footerLogo{
      width: auto;
      height: 58px;
      img{
        max-width: 100%;
        width: auto;
        height: 100%;
      }
  }
  
  @include for-size(phone-only) {
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }
}
.regaBlock {
  cursor: pointer;
  margin-top: 32px;
  @include for-size(phone-only) {
    margin-top: 0px; 
  }
  & img:first-of-type {
    width: 194px;
  }
}
.row {
  display: grid;
  grid-template-columns: 284px 1fr 2fr;
  list-style: none;
  border-bottom: 1px solid #cacbcd;
  grid-column-gap: 48px;
  position: relative;
  grid-row-gap: 50px;
  @include for-size(tablet-portrait-up) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 50px;
  }
  @include for-size(phone-only) {
    grid-template-columns: unset;
    border-color: transparent;
    padding-bottom: 0;
  }
  & .links {
    display: flex;
    flex-direction: column;
    & .storeBtns {
      @media (min-width: 1025px) {
        display: none;
      }
      display: flex;
      flex-direction: column;
      gap: 25px;
      align-items: flex-start;
      margin-bottom: 2em;
      @media (min-width: 481px) and (max-width: 1024px) {
        margin-bottom: 50px;
      }
    }
    & .socialIcons {
      margin: 1rem 0 2em 0;
      @include for-size(phone-only) {
        margin: 0 0 2em 0;
      }
      @media (min-width: 1025px) {
        display: none;
      }
      @media (min-width: 481px) and (max-width: 1024px) {
        margin-bottom: 90px;
      }
    }
  }
}
.staticContent {
  @include for-size(tablet-portrait-up) {
    grid-row-start: 1;
    grid-column-start: 2;
  }
  @include for-size(phone-only) {
    display: none;
  }
}
@media (max-width: 480px) {
  .row {
    & .links {
      flex-direction: column-reverse;
      & .storeBtns {
        flex-direction: row;
        gap: 1em;
        margin-bottom: 0.5em;
        & img {
          width: auto;
          margin-left: 0;
        }
      }
    }
  }
}

.p_175 {
  @include for-size(phone-only) {
    padding-bottom: 99px;
  }
}