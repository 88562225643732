@import './../../../../styles/globalVariables';
@import './../../../../styles/mixins';
.title {
  border-bottom: 2px solid #864ef2;
  // border-image: linear-gradient(90deg, #F39200 6.38%, #864EF2 100%);
  // border-image-slice: 1;
  max-width: 400px;
}
.head {
  margin: 0 0 16px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #510c76;
}
.arrow {
  display: none;
}
.py1 {
  a {
    color: $color-gray;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.25s ease-in-out;
    font-weight: 400;
    &:hover {
      color: #510c76;
    }
  }
}
.fontMedium {
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  cursor: pointer;
}
.listInline {
  padding-top: 24px;
  // padding-bottom: 24px;
}
@media (max-width: 1024px) {
  .title {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 580px) {
  .head {
    font-size: 16px;
    line-height: 24px;
  }
  .title {
    padding: 0;
  }
  .arrow {
    background-repeat: no-repeat;
    width: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
