@import '../../../../styles/globalVariables';
@import '../../../../styles/mixins';
@import '../../../../styles/fx.scss';

// contact us box work
.s_wrap{
    grid-gap: 16px;
    padding: 0 20px 30px;
    display: grid;
}
.tagWrap{
  // padding: 16px 16px;
  display: flex;
  & > div {
    margin-right: 8px;
  }
}
.s_card {
     position: relative;
    width: 100%;
    height: 420px;
    display: grid;
    grid-template-rows: 190px auto 46px;
    border-radius: 8px;
    overflow: hidden;
    background: #ccc;
    -moz-box-align: end;
    align-items: flex-end;
}

.tags{
  height: 34px ;
  width: 105px;
  border-radius: 20px;
  background: linear-gradient(106.51deg, #ECECF0 -9.08%, #D9D9E0 109.23%);
  display: flex;
    place-content: center;
    place-items: center;
    grid-gap: 8px;
    gap: 8px;
}
