@import '../../../../styles/globalVariables';
@import '../../../../styles/mixins';
@import '../../../../styles/fx.scss';

// CSS

.bid {
  // General CSS
  @include for-size(phone-only) {
    // Mobile CSS
  }
}

// contact us box work
.contact_box {
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
  text-align: center;
}

.img_area {
  width: 50%;
  display: none;
  height: 40rem;
  overflow: hidden;
}

.contact_box .contact,
.contact_us,
.text,
.call_logo {
  margin-bottom: 1rem;
}

.call_logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.contact {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  background: -webkit-linear-gradient(#e39532, #a372ec);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.contact_us {
  color: purple;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text {
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.call_circle {
  border-width: 2px;
  border-radius: 9999px;
  padding: 0.375rem;
  margin-right: 1rem;
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}

.icon_text .call_us,
.text,
.number {
  margin-bottom: 0.25rem;
}

.call_us {
  letter-spacing: 0.5rem;
  font-weight: 600;
  color: purple;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.5rem;
  [dir='rtl'] & {
    letter-spacing: 0;
  }
}

.number {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.text_area {
  width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
}

//   terms  component


.term_grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
  @include for-size(phone-only) {
    // Mobile CSS
    gap: 0px;
  }
}
.list_type {
  list-style-type: circle;
}
.list_type > li {
  // General CSS
  margin-bottom: 48px;
  @include for-size(phone-only) {
    // Mobile CSS
    margin-bottom: 30px;
  }
}
.action_term { 
    font-weight: 500;
    font-size: 32px;
    color: #222222;
    padding-bottom:18px;
    line-height: 44px;
}

.action_legal {
  font-weight: 600;
  font-size: 49px;
  padding: 1rem 0rem 1rem 0;
  line-height: 2.5rem;
  -tw-text-opacity: 1;
  color: purple;
  display: flex;
  align-items: center;
  justify-content: center;
  @include for-size(phone-only) {
    // Mobile CSS
    padding: 16px 0px 32px 0px;
  }
}

.text_physical {
  width: 100%;
  text-align: left;
  margin-top: 18px;
  @include for-size(phone-only) {
    margin-top: 0;
  }
  html[dir='rtl'] & {
    text-align: right;
  }
  ul{
    padding:0;
    li{
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 15px;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        left: -17px;
        top: 9px;
        background-color: #19191A;
        width: 3px;
        height: 3px;
        border-radius: 50%;
      }
    }
    @include for-size(phone-only) {
      padding-left: 15px;
    }
  }
}

.list_group {
  list-style: none;
  margin: 0;
  padding: 0;
  // border: 1px solid #ccc;
  // border-radius: .5em;
  width: 27em;
}

.list_group li {
  // border-top: 1px solid #ccc;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list_group li:first-child {
  border-top: 0;
}

@media only screen and (min-width: 768px) {
  .sm_tr {
    display: table-row;
  }

  .main_container {
    // background-color:$bg-white;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .circle {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: #2196f3;
    margin-right: 1rem;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }

  .table th,
  .table td {
    //   border: 1px solid #ddd;
    padding: 1rem 1rem 1rem 1rem;
    text-transform: capitalize;
  }

  .sm_td {
    display: table-cell;
  }

  .sm_hidden {
    display: none;
  }

  .contact_box {
    width: 100%;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: capitalize;
    text-align: left;
  }

  .text_area {
    width: 50%;
  }

  .img_area {
    display: block;
  }

  .call_logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
  }
  .term_grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 4rem;
    list-style-type: circle;
  }
}

@media screen and (max-width: 767px) {
  .action_legal {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
  }
}

.termSection {
  section {
    padding: 0;
  }
}