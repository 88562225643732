@import '../../../styles/functions';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/placeholders/';
@import '../../../styles/globalVariables';

.center {
  text-align: center;
}

.center ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected {
  width: 40px;
  height: 40px;
  border: 1px solid #510C76;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #323335;
  padding: 8px;
  margin: 0px 4px;
}

.nonSelected {
  width: 40px;
  height: 40px;
  border: 1px solid #E5E5E6;
  border-radius: 4px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #323335;
  padding: 8px;
  margin: 0px 4px;
  cursor: pointer;
}

.nonSelected:hover {
  background: #f2f2f3;
  border: 1px solid #f2f2f3;
}

.dot {
  background-color: transparent;
  border: none;
  margin: 0 10px 0 6px;
  transform: rotate(90deg);
}

.prev {
  width: 40px;
  height: 40px;
  background: #6F10A2;
  border: 1px solid #6F10A2;
  border-radius: 4px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  padding: 8px;
  cursor: pointer;
  margin: 0px 4px;

  html[dir='rtl'] & {
    i {
      display: inline-block;
      transform: rotate(180deg);
    }
  }
}

.prev li svg {
  pointer-events: none;
  cursor: default;
  stroke: #fff !important;
  fill: #fff !important;

  [dir='rtl'] & {
    transform: rotateY(180deg);
  }
}

.disable {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  margin: 0px 4px;
  cursor: not-allowed;

  html[dir='rtl'] & {
    i {
      display: inline-block;
      transform: rotate(180deg);
    }
  }
}

.disable li svg {
  pointer-events: none;
  cursor: default;

  [dir='rtl'] & {
    transform: rotateY(180deg);
  }
}

.auctionCount {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #323335;
  text-align: center;
  margin-top: 16px;

}

.pd_90 {
  padding-bottom: 28px;
}

.pd_150 {
  padding-bottom: 150px;

  @include for-size(tablet-phone) {
    padding-bottom: 40px;
  }
}